.image-container {
    width: 200px;
    height: 300px; /* İstediğiniz yüksekliğe ayarlayın */
    display: flex;
    justify-content: center;
    align-items: center;
     }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit:scale-down; /* Resmin orantısını koruyarak kutuya sığmasını sağlar */
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .dark-theme {
    background-color: #343a40;
    color: white;
  }
  
  .dark-theme .card {
    background-color: #23272b;
    border-color: #1d2124;
  }
  
  .dark-theme .card-header, .dark-theme .card-footer {
    background-color: #1d2124;
  }
  
  /* Ve diğer gerekli stiller... */
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: orangered;
    background-color: hsl(0, 9%, 13%);
    border-color:  #1d2124;
}

.nav-tabs {
    border:   #1d2124;
    color: orangered;
}

.nav-tabs .nav-link:hover{
  color: #0d8280;
  background-color:  #1d2124;
  border:  #1d2124;
}
.tabitem {
  font-family: Source Sans Pro;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.77;
  letter-spacing: -0.6px;
  text-align: left;
  color: #d9d9d9;
}