/* SignIn.css */
.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Tam sayfa yüksekliği */
  background-color: #181818; /* Özelleştirilmiş arka plan rengi */
  color: #fff; /* Yazı rengi */
}

.signin-container .signin-box {
  width: 100%;
  max-width: 320px; /* Maksimum genişlik */
  padding: 20px;
  background: #202020; /* Form arka planı */
  border-radius: 10px; /* Kenar yuvarlaklığı */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Gölge efekti */
}

.signin-container .signin-box input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px; /* Input altındaki boşluk */
  border: none;
  border-radius: 5px; /* Input kenar yuvarlaklığı */
  background: #303030; /* Input arka planı */
  color: #fff; /* Input yazı rengi */
}
.button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px; /* Buton altındaki boşluk */
  border: none;
  border-radius: 5px; /* Buton kenar yuvarlaklığı */
  background: #04aec6; /* Buton arka planı */
  color: #fff; /* Buton yazı rengi */
  cursor: pointer; /* İmleç efekti */
}

/* Buton hover efekti */
.button:hover {
  background: #0290a1;
}

.signin-container .signin-box button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px; /* Buton altındaki boşluk */
  border: none;
  border-radius: 5px; /* Buton kenar yuvarlaklığı */
  background: #04aec6; /* Buton arka planı */
  color: #fff; /* Buton yazı rengi */
  cursor: pointer; /* İmleç efekti */
}

/* Buton hover efekti */
.signin-container .signin-box button:hover {
  background: #0290a1;
}

/* Diğer stil tanımlamalarınız */

.div {
    background-color: #131313;
    display: flex;
    flex-direction: column;
    padding: 49px 22px 27px 70px;
  }
  @media (max-width: 991px) {
    .div {
      padding: 0 20px;
    }
  }
  .div-2 {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0 2px;
  }
  @media (max-width: 991px) {
    .div-2 {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  .div-3 {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 991px) {
    .div-3 {
      max-width: 100%;
    }
  }
  .img {
    aspect-ratio: 5.75;
    object-fit: contain;
    object-position: center;
    width: 460px;
    overflow: hidden;
  }
  @media (max-width: 991px) {
    .img {
      max-width: 100%;
    }
  }
  .div-4 {
    color: #fff;
    text-transform: capitalize;
    align-self: stretch;
    margin-top: 52px;
    font: 600 96px Montserrat, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-4 {
      max-width: 100%;
      font-size: 40px;
      margin-top: 40px;
    }
  }
  .div-5 {
    color: #efefef;
    text-transform: capitalize;
    align-self: stretch;
    margin-top: 45px;
    font: 400 14px Poppins, sans-serif;
  }
  @media (max-width: 991px) {
    .div-5 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  @media (max-width: 640px) {
    .div-5 {
      margin-bottom: 50px;
    }
  }
  .div-6 {
    align-self: end;
    display: flex;
    margin-top: 56px;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    align-items: end;
  }
  @media (max-width: 991px) {
    .div-6 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .div-7 {
    justify-content: center;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    border-radius: 15px;
    background-color: #887086;
    margin-right: 40px;
    width: 135px;
    max-width: 100%;
    align-items: center;
    padding: 19px 20px;
    font: 600 16px Poppins, sans-serif;
  }
  @media (max-width: 991px) {
    .div-7 {
      margin-right: 10px;
    }
  }
  .div-8 {
    border-radius: 29px;
    background-color: #272727;
    align-self: stretch;
    margin-top: 42px;
    padding: -95px -12px;
  }
  @media (max-width: 991px) {
    .div-8 {
      max-width: 100%;
      padding-right: 20px;
      margin-top: 40px;
    }
  }
  .div-9 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .div-9 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 47%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column {
      width: 100%;
    }
  }
  .img-2 {
    aspect-ratio: 0.48;
    object-fit: contain;
    object-position: center;
    width: 209px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    margin-top: -143px;
    max-width: 100%;
  }
  @media (max-width: 991px) {
    .img-2 {
      margin-top: -119px;
    }
  }
  .column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 53%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-2 {
      width: 100%;
    }
  }
  .div-10 {
    display: flex;
    flex-direction: column;
    margin: auto 0;
  }
  @media (max-width: 991px) {
    .div-10 {
      margin-top: 40px;
    }
  }
  .div-11 {
    color: #fff;
    text-transform: capitalize;
    justify-content: space-between;
    gap: 20px;
    font: 400 32px/39px Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .div-12 {
    color: #5c5c5c;
    text-transform: capitalize;
    margin-top: 33px;
    font: 400 16px Poppins, sans-serif;
  }
  .div-13 {
    margin-top: 40px;
  }
  @media (max-width: 991px) {
    .div-13 {
      max-width: 100%;
    }
  }
  .div-14 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .div-14 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 28%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column-3 {
      width: 100%;
    }
  }
  .div-15 {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .div-15 {
      margin-top: 40px;
    }
  }
  .div-16 {
    justify-content: center;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    border-radius: 15px;
    background-color: #710a48;
    align-items: start;
    padding: 20px 20px 20px 36px;
    font: 600 16px Poppins, sans-serif;
  }
  @media (max-width: 991px) {
    .div-16 {
      padding-left: 10px;
    }
  }
  .div-17 {
    border-radius: 29px;
    background-color: #710a48;
    display: flex;
    margin-top: 115px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 24px 22px 24px 28px;
  }
  @media (max-width: 991px) {
    .div-17 {
      margin-top: 40px;
      padding: 0 20px;
    }
  }
  .div-18 {
    align-self: stretch;
    display: flex;
    padding-right: 7px;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
  }
  .div-19 {
    justify-content: center;
    color: #fff;
    text-transform: capitalize;
    margin-top: 13px;
    font: 600 32px/39px Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .img-3 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 52px;
    fill: #d9d9d9;
    overflow: hidden;
    max-width: 100%;
  }
  .div-20 {
    color: #efefef;
    text-transform: capitalize;
    align-self: stretch;
    margin-top: 24px;
    font: 400 14px Poppins, sans-serif;
  }
  .img-4 {
    aspect-ratio: 6.54;
    object-fit: contain;
    object-position: center;
    width: 301px;
    overflow: hidden;
    margin-top: 44px;
  }
  @media (max-width: 991px) {
    .img-4 {
      margin-top: 40px;
    }
  }
  .column-4 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 72%;
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-4 {
      width: 100%;
    }
  }
  .div-21 {
    display: flex;
    margin-top: 10px;
    flex-grow: 1;
    flex-direction: column;
    align-items: start;
  }
  @media (max-width: 991px) {
    .div-21 {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .div-22 {
    display: flex;
    flex-direction: column;
    fill: #f7e9d7;
    overflow: hidden;
    position: relative;
    display: flex;
    min-height: 344px;
    width: 624px;
    max-width: 100%;
    padding: 31px 17px 31px 55px;
  }
  @media (max-width: 991px) {
    .div-22 {
      padding-left: 20px;
    }
  }
  .img-5 {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .div-23 {
    position: relative;
    align-self: start;
    display: flex;
    width: 223px;
    max-width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 0 1px;
  }
  @media (max-width: 640px) {
    .div-23 {
      width: 148px;
    }
  }
  .div-24 {
    transform: rotate(-0.288deg);
    color: #131313;
    text-transform: capitalize;
    font: 600 24px/30px Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 640px) {
    .div-24 {
      margin-right: -2px;
    }
  }
  .img-6 {
    aspect-ratio: 0.83;
    object-fit: contain;
    object-position: center;
    width: 31px;
    overflow: hidden;
    align-self: start;
    margin-top: 4px;
    max-width: 100%;
  }
  @media (max-width: 640px) {
    .img-6 {
      margin-left: -5px;
    }
  }
  .div-25 {
    position: relative;
    transform: rotate(-0.288deg);
    color: #5c5c5c;
    text-transform: capitalize;
    align-self: stretch;
    padding-right: 200px;
    margin: 13px 91px 44px 0;
    font: 400 14px Poppins, sans-serif;
  }
  @media (max-width: 991px) {
    .div-25 {
      max-width: 100%;
    }
  }
  @media (max-width: 640px) {
    .div-25 {
      margin: 0 104px 81px 0;
      padding: 10px 0 54px;
    }
  }
  .div-26 {
    position: relative;
    align-self: end;
    display: flex;
    margin-top: 43px;
    width: 205px;
    max-width: 100%;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
  }
  @media (max-width: 991px) {
    .div-26 {
      margin-top: 40px;
    }
  }
  .div-27 {
    transform: rotate(-0.288deg);
    color: #131313;
    text-transform: capitalize;
    margin-top: 5px;
    font: 600 64px/79px Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-27 {
      font-size: 40px;
      line-height: 54px;
    }
  }
  @media (max-width: 640px) {
    .div-27 {
      padding-left: 46px;
      margin: 0 15px 0 8px;
    }
  }
  .img-7 {
    aspect-ratio: 0.9;
    object-fit: contain;
    object-position: center;
    width: 42px;
    fill: #710a48;
    overflow: hidden;
    z-index: 1;
    max-width: 100%;
  }
  .div-28 {
    position: relative;
    transform: rotate(-0.288deg);
    color: #5c5c5c;
    text-transform: capitalize;
    padding-left: 200px;
    margin: 10px 0 4px 100px;
    font: 400 14px Poppins, sans-serif;
  }
  @media (max-width: 640px) {
    .div-28 {
      margin-left: 21px;
      padding-left: 160px;
    }
  }
  .div-29 {
    align-self: end;
    display: flex;
    margin-top: 48px;
    width: 406px;
    max-width: 100%;
    flex-direction: column;
    align-items: start;
  }
  @media (max-width: 991px) {
    .div-29 {
      margin-top: 40px;
    }
  }
  .div-30 {
    justify-content: center;
    color: #fff;
    text-transform: capitalize;
    margin-left: 31px;
    max-width: 363px;
    font: 400 32px/39px Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-30 {
      margin-left: 10px;
    }
  }
  .div-31 {
    align-self: stretch;
    display: flex;
    margin-top: 14px;
    justify-content: space-between;
    gap: 14px;
    padding: 0 -1px;
  }
  .div-32 {
    justify-content: center;
    color: #0b0b0b;
    text-align: center;
    text-transform: capitalize;
    border-radius: 36px;
    border: 1px solid #d9d9d9;
    background-color: #d9d9d9;
    flex-grow: 1;
    align-items: center;
    padding: 15px 20px;
    font: 400 20px/25px Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .div-33 {
    justify-content: center;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    border-radius: 36px;
    border: 1px solid #d9d9d9;
    z-index: 1;
    flex-grow: 1;
    align-items: center;
    padding: 13px 20px;
    font: 400 20px/25px Poppins, -apple-system, Roboto, Helvetica,
      sans-serif;
  }



/* Modal penceresi stil tanımlamaları */
.modal {
  display: flex;
  align-items: center; /* Dikey hizalama */
  justify-content: center; /* Yatay hizalama */
  position: fixed;
  z-index: 1000; /* Yeterli bir z-index değeri */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Siyah arka plan ile biraz şeffaflık */
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: auto; /* İhtiyaca göre ayarlayın */
  height: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* Yeni eklenen transform ile merkezleme */
  transform: translate(-50%, -30%);
  position: absolute;
  top: 30%;
  left: 50%;
}

/* Modal penceresini aktif ettiğinizde */
.modal.active {
  display: block; /* Modalı göster */
}



.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



