/**
  * Name: One Admin
  * Version: 1.0
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/
@import url("mCustomScrollbar.css");
@import url("font-awesome.css");
@import url("waves.min.css");
@import url("material-design-iconic-font.min.css");
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Oswald:200,300,400,500,600,700');

/**
 *	Reset
  *	Repeatable Patterns
  *	Header
  * Navbar Top
  * Vertical Navigation
  *	Member Status
  * Main
  *	Box
  *	Legend
  *	Box Message
  *	Message Info
  *	Preload
*/

/* Reset
-------------------------------------------------------------- */
html {
	overflow-y: scroll;
	-webkit-text-size-adjust: 100%;
	   -ms-text-size-adjust: 100%;
}

body {
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	   -ms-text-size-adjust: 100%;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, caption, canvas, center, cite, code,
dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, form, footer, header, hgroup, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav,object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, tt, table, tbody, textarea, tfoot, thead, time, tr, th, td, u, ul, var, video  { 
	font-family: inherit; 
	font-size: 100%; 
	font-weight: inherit; 
	font-style: inherit; 
	vertical-align: baseline; 
	margin: 0; 
	padding: 0; 
	border: 0; 
	outline: 0;
	background: transparent;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { 
	display: block;
}
                          
ol, ul {
	list-style: none;
}

blockquote, q { 
	-webkit-hyphens: none;
	  -moz-hyphens: none;
	   -ms-hyphens: none;
	       hyphens: none;
	        quotes: none;
}

figure {
	margin: 0;
}

:focus {
	outline: 0;
}

table { 
	border-collapse: collapse; 
	border-spacing: 0;
}

img {
	border: 0;
	-ms-interpolation-mode: bicubic;
	vertical-align: middle;
}

legend {
	white-space: normal;
}

button,
input,
select,
textarea {
	font-size: 100%;
	margin: 0;
	max-width: 100%;
	vertical-align: baseline;
	-webkit-box-sizing: border-box;
	  -moz-box-sizing: border-box;
	       box-sizing: border-box;
}

button,
input {
	line-height: normal;
}

input,
textarea {
	background-image: -webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0)); /* Removing the inner shadow, rounded corners on iOS inputs */
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	line-height: 1;
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
	border: none;
}

input[type="checkbox"],
input[type="radio"] {
	padding: 0; /* Addresses excess padding in IE8/9 */
}

input[type="search"] {
	-webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
}

input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner { /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
	border: 0;
	padding: 0;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

/* Repeatable Patterns
-------------------------------------------------------------- */
body {
	font: 14px "Montserrat", sans-serif;
	background-color: #18191c;		
	color: #898989;
	overflow: hidden;
	font-weight: 400;
}

a {		
	text-decoration: none;
	color: #898989;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		 -o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
	color: #04aec6;
	text-decoration: none;
	outline: 0;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		 -o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
}

ul, ol {
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;		
}

b, strong {
	font-weight: 900;
}

button,
button:hover {
	border: none;
	background-color: transparent;
}



select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input[type="email"] {	
	position: relative;
    width: 100%;
    line-height: 20px;
    padding: 15px 12px;
    color: #898989;
    border-radius: 30px;
    font-size: 12px;
    height: 60px;
    background-color: transparent;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

input[type="text"] {
	border:  1px solid #2f3136;
	height: 50px;
	padding: 15px 30px;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
	-webkit-box-shadow: none;
	   -moz-box-shadow: none;
			box-shadow: none;
}

textarea:-moz-placeholder,
textarea::-moz-placeholder,
input:-moz-placeholder,
input::-moz-placeholder {		
	color: #898989;
	opacity: 1;
}

input:-ms-input-placeholder {
	color: #898989;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
	color: #898989;
	opacity: 1;
}

/* bootstrap resetting elements */
.btn {
	background-image: none;
}

select,
textarea, 
input[type="text"],
input[type="submit"],
input[type="password"], 
input[type="datetime"], 
input[type="datetime-local"], 
input[type="date"], 
input[type="month"], 
input[type="time"], 
input[type="week"], 
input[type="number"], 
input[type="email"], 
input[type="url"], 
input[type="search"], 
input[type="tel"], 
input[type="color"], 
.uneditable-input,
.dropdown-menu,
.navbar .nav > .active > a, 
.navbar .nav > .active > a:hover, 
.navbar .nav > .active > a:focus {
	-webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
	text-shadow: none;
	-webkit-box-shadow: none;
	   -moz-box-shadow: none;
	     -o-box-shadow: none;
	        box-shadow: none;
	color: #727272;
}

select::-ms-expand {
 /* IE 8 */
 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
 /* IE 5-7 */
 filter: alpha(opacity=0);
 /* Good browsers :) */
 opacity:0;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}

h1 { font-size: 32px; line-height: 66px; }
h2 { font-size: 30px; line-height: 24px; }
h3 { font-size: 24px; line-height: 24px; font-weight: 500; }
h4 { font-size: 16px; line-height: 24px; }

.clearfix {
	clear: both;
}

.background-color {
	background-color: #f5f5f5;
}

.float-left {float: left;}
.float-right {float: right;}

/* Header
-------------------------------------------------------------- */
header {
	width: 100%;
	/* position: fixed; */
}

/* Header Fix */
/* .header.downscrolled {
	position: fixed;
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.8);
	top: 0px;
	opacity: 0;
	width: 100%;
	left: 0;
	z-index: 9999;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
	filter: alpha(opacity=0);
	visibility: hidden;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
} 

.header.upscrolled {
	opacity: 1;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	filter: alpha(opacity=100);
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
} */ 

/* Navbar Top
-------------------------------------------------------------- */
.navbar-top {
	position: relative;
	z-index: 99999;
	background: -webkit-linear-gradient(90deg, #202226, #18191c); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(90deg, #202226, #18191c); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(90deg, #202226, #18191c); /* For Firefox 3.6 to 15 */
	background: linear-gradient(90deg, #202226, #18191c); /* Standard syntax */
}

.navbar-top .curren-menu {
	float: left;
	display: inline-flex;
}

.navbar-top .curren-menu > div {
	display: inline-block;
}

.navbar-top .curren-menu > div.logo {
    line-height: 113px;
    width: 180px;
    height: 100px;
    overflow: hidden;
    text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.navbar-top .curren-menu > div.logo.active {
	width: 308px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;

}

.navbar-top .curren-menu > div.top-button {
	position: relative;
	cursor: pointer;
	height: 25px;
    top: 38px;
}

.navbar-top .curren-menu > div.top-button span {
	height: 2px;
	width: 34px;
	background-color: #c5c5c5;
	display: block;
	margin-left: 10px;
}

.navbar-top .curren-menu > div.top-button span:after {
	content: '';
	position: absolute;
	height: 2px;
    width: 33px;
    background-color: #c5c5c5;
    top: 11px;
    left: 1px;
		-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.navbar-top .curren-menu > div.top-button.active span:after {
	left: 10px;
		-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.navbar-top .curren-menu > div.top-button span:before {
	content: '';
	position: absolute;
	height: 2px;
	width: 34px;
	background-color: #c5c5c5;
	top: 22px;
	left: 10px;
}

.navbar-top .curren-menu > div.box-search {
	position: relative;
	margin-left: 86px;
	line-height: 95px;
}

.navbar-top .curren-menu > div.box-search button {
	position: absolute;
	top: 30px;
	right: 23px;
	z-index: 9;
}

.navbar-top .curren-menu > div.box-search input {
	width: 375px;
}

.navbar-top ul.info-right {
	float: right;
}

.navbar-top ul.info-right > li {
	display: inline-block;
    margin-left: 18px;
    vertical-align: middle;
    margin-top: 27px;
}

.navbar-top ul.info-right li.user {
	margin-left: 21px;
	margin-right: 30px;
	position: relative;
	cursor: pointer;
}

.navbar-top ul.info-right li.setting a,
.navbar-top ul.info-right li.notification a {
	display: block;
	background-color: #6256a9;
	height: 43px;
	width: 43px;
	text-align: center;
	line-height: 43px;
	border-radius: 50%;
	color: #fff;
	z-index: 2;
	position: relative;
	-moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


.navbar-top ul.info-right li.setting a:hover,
.navbar-top ul.info-right li.notification a:hover {
	background-color: #00bcd5;
}

.navbar-top ul.info-right li.user .avatar {
	float: left;
    margin-right: 30px;
    width: 45px;
    height: 45px;
}

.navbar-top ul.info-right li.user .info {
	float: left;
	margin-right: 42px;
}

.navbar-top ul.info-right li.user .avatar img {
	border-radius: 50%;
}

.navbar-top ul.info-right li.user .arrow-down {
	float: left;
	font-size: 20px;
	margin-top: 7px;
}

.navbar-top ul.info-right li.user .arrow-down .fa-angle-up {
	display: none;
}

.navbar-top ul.info-right li.user.open .arrow-down .fa-angle-up {
	display: block;
}

.navbar-top ul.info-right li.user.open .arrow-down .fa-angle-down {
	display: none;
}

.navbar-top ul.info-right li.user .dropdown-menu {
	position: absolute;
	right: 0;
	left: auto;
	width: 100%;
	top: 68px;
	border-radius: 0px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    -webkit-transform-origin: top 0px;
    -ms-transform-origin: top 0px;
    transform-origin: top 0px;
}

.navbar-top ul.info-right li.user.open .dropdown-menu {
	visibility: visible;
	opacity: 1;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top 0px;
    -ms-transform-origin: top 0px;
    transform-origin: top 0px;
}

.navbar-top ul.info-right li.user .dropdown-menu.active {
	display: block;
}

.navbar-top ul.info-right li.user .dropdown-menu li a {
	padding: 10px 20px;
	display: block;
}

.navbar-top ul.info-right li.user .dropdown-menu li a:hover {
	background-color: #04aec6;
	color: #fff;
}

.navbar-top ul.info-right li.user .dropdown-menu li:first-child {
	padding: 10px 20px;
}

.navbar-top ul.info-right li.user .dropdown-menu li:not(:last-child) {
	border-bottom: 1px solid rgba(0,0,0,.15);
}

.navbar-top ul.info-right li.user .dropdown-menu ul li .avatar {
	margin-right: 15px;
}

.navbar-top ul.info-right li.user .dropdown-menu ul li .profile {
	margin: 12px 0 0;
	float: left;
}

.navbar-top ul.info-right li.user .dropdown-menu ul li .profile a {
	padding: 5px 9px;
    background: #6256a9;
    border-radius: 5px;
    color: #fff;
}

.navbar-top ul.info-right li.user .dropdown-menu ul li .profile a:hover {
	background-color: #04aec6;
}

.navbar-top ul.info-right li.user .info .name {
	font-family: 'Oswald';
	font-size: 16px;
	font-weight: 500;
	color: #c5c5c5;
	margin-top: 2px;
	letter-spacing: 0.8px;
}

.navbar-top ul.info-right li.user .info .address {
    font-size: 10px;
    font-family: 'Oswald';
    margin-top: 1px;
    margin-left: 2px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.navbar-top ul.info-right li.button-menu-right {
	margin-right: 40px;
	cursor: pointer;
}

.navbar-top ul.info-right li.button-menu-right img {
	-webkit-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;	
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.navbar-top ul.info-right li.button-menu-right.active img {
	-webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;	
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

/* Vertical Navigation
-------------------------------------------------------------- */
.vertical-navigation.left {
	float: left;
	width: 180px;
	position: absolute;
	z-index: 99;
    background: -webkit-linear-gradient(180deg, #202226, #18191c); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(180deg, #202226, #18191c); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(180deg, #202226, #18191c); /* For Firefox 3.6 to 15 */
	background: linear-gradient(180deg, #202226, #18191c); /* Standard syntax */
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;	
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.vertical-navigation.left .user-profile {
	padding: 35px 34px 39px;
}

.vertical-navigation.left .user-profile .user-img {
    margin-bottom: 15px;
    text-align: center;
    margin-left: -3px;
}

.vertical-navigation.left .user-profile ul.user-options {
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;	
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.vertical-navigation.left .user-profile ul.user-options .name {
	font-size: 16px;
	font-weight: 700;
	font-family: 'Oswald';
	color: #c5c5c5;
	letter-spacing: 1px;
}

.vertical-navigation.left .user-profile ul.user-options .options {
    font-size: 10px;
    font-weight: 200;
    font-family: 'Oswald';
    letter-spacing: 0.7px;
    padding-left: 16px;
    margin-top: 3px;
}

.vertical-navigation.left .user-profile .user-img a {
	display: inline-block;
	position: relative;
}

.vertical-navigation.left .user-profile .user-img img {
	border-radius: 50%;
}

.vertical-navigation.left ul.sidebar-nav > li {
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.vertical-navigation.left ul.sidebar-nav > li {
	display: block;
	padding: 25px 0;
	margin-left: -7px;
}

.vertical-navigation.left ul.sidebar-nav > li > div.img-nav > img {
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.vertical-navigation.left ul.sidebar-nav > li > div.img-nav {
	display: inline-block;
	position: relative
}

.vertical-navigation.left ul.sidebar-nav > li > div.img-nav > span {
	position: absolute;
	font-family: 'Montserrat';
    top: -3px;
    right: -3px;
    background-color: #04aec6;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    height: 16px;
    width: 16px;
    line-height: 18px;
	text-align: center;
	border-radius: 50%;
}

.vertical-navigation.left ul.sidebar-nav > li.active,
.vertical-navigation.left ul.sidebar-nav > li:hover {
	background-color: #18191c;
}

.vertical-navigation.left ul.sidebar-nav li span {
	display: block;
	font-family: 'Oswald';
	font-weight: 200;
	font-size: 14px;
	color: #c5c5c5;
    margin-top: 11px;
    letter-spacing: 0.5px;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

/* Vertical Navigation Active */
.vertical-navigation.left.active {
	width: 290px;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.vertical-navigation.left.active .user-profile ul.user-options {
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.vertical-navigation.left.active ul.sidebar-nav > li.user-profile {
	text-align: center;
}

.vertical-navigation.left.active ul.sidebar-nav > li {
	text-align: left;
}

.vertical-navigation.left.active ul.sidebar-nav > li > div.img-nav {
	padding-left: 69px;
	margin-right: 30px;
}

.vertical-navigation.left.active ul.sidebar-nav > li > div.img-nav > img {
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.vertical-navigation.left ul.sidebar-nav li.user-profile .user-img {
	padding: 0;
}

.vertical-navigation.left.active ul.sidebar-nav li span {
	display: inline-block;
	font-size: 20px;

}

.vertical-navigation.left.active.show {
	width: 308px;
	display: block;
	opacity: 1;
	visibility: visible;
	transform: translateX(0%);
}

/* Status Color */
.status-color.blue {
	position: absolute;
    display: inline-block;
    width: 15px;
    height: 15px;
    bottom: 3px;
    right: 9px;
    z-index: 2;
    background: #04aec6;
    border-radius: 50%;
    border: 3px solid #202226;
}

.status-color.blue.style2 {
    width: 12px;
    height: 12px;
    right: 6px;
    bottom: 2px;
    z-index: 2;
	border: 2px solid #202226;
}

.status-color.green {
	position: absolute;
    display: inline-block;
    width: 12px;
    height: 12px;
    bottom: 0px;
    right: 4px;
    z-index: 2;
    background: #89e07d;
    border-radius: 50%;
    border: 2px solid #202226;
}

.status-color.pink {
	position: absolute;
    display: inline-block;
    width: 12px;
    height: 12px;
    bottom: 0px;
    right: 4px;
    z-index: 2;
    background: #ff2d78;
    border-radius: 50%;
    border: 2px solid #202226;
}

.status-color.grey {
	position: absolute;
    display: inline-block;
    width: 13px;
    height: 13px;
    bottom: 0px;
    right: 2px;
    z-index: 2;
    background: #c9c9c9;
    border-radius: 50%;
    border: 2px solid #202226;
}

.status-color.purple {
	position: absolute;
    display: inline-block;
    width: 13px;
    height: 13px;
    bottom: -1px;
    right: 6px;
    background: #574c9c;
    border-radius: 50%;
    border: 2px solid #fff;
}

/* Member Status
-------------------------------------------------------------- */
.member-status.right.closed {
	width: 0;
	z-index: 99;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.member-status.right {
	float: right;
	background-color: #202226;
	width: 295px;
	background: -webkit-linear-gradient(180deg, #202226, #18191c); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(180deg, #202226, #18191c); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(180deg, #202226, #18191c); /* For Firefox 3.6 to 15 */
	background: linear-gradient(180deg, #202226, #18191c); /* Standard syntax */
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.member-status .sidebar-member .scroll 
{
	height: 900px;
	overflow: auto;
}

.member-status.right .sidebar-member ul.member-tab {
	word-spacing: -4px;
	border-bottom: 2px solid #373737;
}

.member-status.right .sidebar-member ul.member-tab li {
	display: inline-block;
	width: 50%;
	padding: 27px 0 20px;
	position: relative;
	text-align: center;
	cursor: pointer;
}

.member-status.right .sidebar-member ul.member-tab li i {
	font-size: 22px;
}

.member-status.right .sidebar-member ul.member-tab li.active,
.member-status.right .sidebar-member ul.member-tab li:hover {
	color: #6256a9;
}

.member-status.right .sidebar-member ul.member-tab li.active:after {
	content: '';
	position: absolute;
	height: 2px;
	width: 100%;
	background-color: #6256a9;
	bottom: -2px;
	left: 0;
}

.member-status.right .sidebar-member ul.member-list.online {
	margin-top: 33px;
	padding-left: 50px;
}

.member-status.right .sidebar-member ul.member-list.offline {
	margin-top: 51px;
	padding-left: 50px;
}

.member-status.right .sidebar-member ul.member-list li.member-header {
	margin-bottom: 17px;
	font-size: 14px;
	font-family: 'Oswald';
	font-weight: 500;
	color: #bdbdbd;
	letter-spacing: 1px;
}

.member-status.right .sidebar-member ul.member-list li {
	margin-bottom: 30px;
}

.member-status.right .sidebar-member ul.member-list li a {
	display: block;
	color: #bdbdbd;
}

.member-status.right .sidebar-member ul.member-list li .avatar {
	float: left;
	height: 45px;
	width: 45px;
	margin-right: 30px;
	position: relative;
}

.member-status.right .sidebar-member ul.member-list li .avatar img {
	border-radius: 50%;
}

.member-status.right .sidebar-member ul.member-list li .info-user {
	padding-top: 5px;
}

.member-status.right .sidebar-member ul.member-list li .info-user .name {
	font-size: 14px;
	font-family: 'Oswald';
	font-weight: 500;
	color: #bdbdbd;
	letter-spacing: 0.7px;
}

.member-status.right .sidebar-member ul.member-list li .info-user .options {
	font-size: 10px;
	font-weight: 500;
	font-family: 'Oswald';
	letter-spacing: 0.5px;
}

.load-more a {
	font-family: 'Oswald';
	font-size: 14px;
	font-weight: 500;
}

/* Main
-------------------------------------------------------------- */
main {
	float: left;
    width: calc(100% - 180px - 295px);
    margin-top: 20px;
    margin-left: 180px;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

/* main.active {
	width: calc(100% - 308px - 295px);
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
} */

main.open {
	width: calc(100% - 180px);
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

/* main.open.active {
	width: calc(100% - 308px);
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
} */

/* Rows
-------------------------------------------------------------- */
.rows {
	padding: 0 20px 20px;
}

#chartStatistics {
	height: 330px;
	width: 100%;
}


#chart-stackedColumn {
	height: 290px;
	width: 100%;
}

#lineChart {
	height: 285px;
	width: 100%;
}

#chart-spline,
#bubble-chart {
	height: 270px;
	width: 100%;
}

#chartdiv {
	height: 250px;
	width: 100%;
}

/* Status Bar */
.status-bar ul li {
	display: inline-block;
	width: calc(25% - 6px);
    margin-right: 4px;
	border-radius: 8px;
	/* box-shadow: inset 0px 0px 90px 0px rgba(0,0,0,0.18); */
	background-color: #242629;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

.status-bar ul li:last-child {
	margin-right: 0px;
}

.status-bar ul li .icon {
	float: left;
    line-height: 84px;
    height: 84px;
    padding: 0 30px 0 27px;
    margin-right: 18px;
	background-color: #1d1f23;
}

.status-bar ul li .content .numb {
	font-family: 'Oswald';
	font-size: 54px;
	font-weight: 500;
	line-height: 80px; 
	color: #f9f9f9;
	float: left;
	margin-right: 18px;
}

.status-bar ul li .content .text {
	font-family: 'Oswald';
	font-size: 14px;
	font-weight: 200;
	color: #f0f0f0;
	line-height: 80px;
}

/* Box
-------------------------------------------------------------- */
.box {
	float: left;
	padding: 19px 30px 45px 30px;
	border-radius: 8px;
	position: relative;
	box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.22);
	background: -webkit-linear-gradient(0deg, #18191c, #18191c 48%, #202226); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(0deg, #18191c, #18191c 48%, #202226); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(0deg, #18191c, #18191c 48%, #202226); /* For Firefox 3.6 to 15 */
	background: linear-gradient(0deg, #18191c, #18191c 48%, #202226); /* Standard syntax */
}

.box.left {
	margin-right: 10px;
}

.box.right {
	margin-left: 10px;
}

.box .box-header .box-title {
	float: left;
	position: relative;
}

.box .box-header .box-title h3 {
	font-size: 14px;
	font-weight: bold;
}

.box .box-header .box-title span {
    position: absolute;
    top: -11px;
    right: -33px;
    font-size: 10px;
    font-weight: 500;
    color: #ebebeb;
    background-color: #4c418b;
    line-height: 30px;
    height: 28px;
    width: 27px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
}

.box .pull-right {
	position: relative;
	text-align: center;
	line-height: 10px;
}

.box .pull-right i {
	font-size: 26px;
	line-height: 24px;
	position: relative;
	cursor: pointer;
	border-radius: 50%;
	display: inline-block;
}

.box .pull-right ul.dropdown-menu {
	background-color: #fff;
	color: #898989;
	border-radius: 5px;
	overflow: hidden;
	padding: 0;
	margin: 0;
	left: auto;
	right: 0;
	position: absolute;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    display: inline-block;
    -webkit-transform-origin: top 0px;
    -ms-transform-origin: top 0px;
    transform-origin: top 0px;
}

.box .pull-right ul.dropdown-menu li:not(:last-child) {
	border-bottom: 1px solid rgba(0,0,0,.15);
}

.box .pull-right ul.dropdown-menu li a {
	padding: 8px 15px;
	display: block;
}

.box .pull-right ul.dropdown-menu li a:hover {
	background-color: #4c418b;
	color: #fff;
}

.box .pull-right.open ul.dropdown-menu {
-webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top 0px;
    -ms-transform-origin: top 0px;
    transform-origin: top 0px;
    visibility: visible;
    opacity: 1;
}

.box .pull-right ul.dropdown-menu.active {
	display: block;
}

.box .box-content {
	/* width: 100%; */
	/* height: 100%; */
}

.box-danger,
.box-radar {
	width: calc(38% - 16px);
}

.box-statistics,
.box-line {
	width: calc(62% - 4px);
}

.box-stackedColumn,
.box-line {
    width: calc(62% - 20px);
    padding: 19px 30px 31px;
}

.box-spline,
.box-radar {
	width: calc(38% - 0px);
}

.box-bubble,
.box-map,
.box-project,
.box-inbox {
	width: calc(50% - 10px);
	overflow-y: auto;
}
.box-kullanici {
	width: calc(50% - 10px);
	overflow-y: auto;
}
/* Box Danger */
.box-danger {
	height: 453px;
}

.box-danger .chart-doughnut .chart {
	float: left;
}

/* Box Spline */
.box-spline.right {
	margin-left: 10px;
	padding: 19px 30px 31px;
}

/* Box Map */
.box-map {
	padding: 21px 30px 34px 30px;
}

/* Box Bubble */
.box-bubble {
	padding: 21px 30px 42px 30px;
}

/* Box Line */
.box-line {
    padding: 19px 30px 18px 30px;
}

/* Box Project */
.box-project,
.box-inbox {
    padding: 22px 30px 19px 29px;
   
}
.box-kullanici {
    padding: 22px 30px 19px 29px;
    height: 800px;
}
/* Box Radar */
.box-radar {
	min-height: 396px;
}

/* .box-radar .box-content canvas {
	width: 100% !important;
} */

/* Chart Tab */
.box .box-content.style1 {
	padding: 0 37px;
}

.box-content.style2 {
	padding: 0 18px;
}

ul.chart-tab {
	text-align: right;
	margin-right: 14px;
	margin-bottom: 3px;
}

ul.chart-tab li {
	display: inline-block;
	font-size: 12px;
	font-weight: 400;
	font-family: 'Montserrat';
	width: 80px;
	line-height: 26px;
	border: 1px solid #898989;
	text-align: center;
	border-radius: 25px;
	margin-left: 7px;
	cursor: pointer;
}

ul.chart-tab li:hover,
ul.chart-tab li.active {
	background-color: #5b57a3;
	color: #ffffff;
	border-color: #5b57a3;
	-webkit-transition: all 0.3s ease-in-out;
	   -moz-transition: all 0.3s ease-in-out;
	    -ms-transition: all 0.3s ease-in-out;
	     -o-transition: all 0.3s ease-in-out;
	        transition: all 0.3s ease-in-out;
}

/* Legend
-------------------------------------------------------------- */
.legend ul.legend-list {
	text-align: center;
}

.legend ul.legend-list li {
	display: inline-block;
	font-size: 10px;
	font-weight: 500;
	padding-left: 18px;
	margin: 0 10px;
	font-family: 'Montserrat';
}

.legend ul.legend-list li {
	position: relative;
	color: #4c418b;
}

.legend ul.legend-list li.ui {
	color: #04aec6;
}

.legend ul.legend-list li.code {
	color: #ff2d78;
}

.legend ul.legend-list li:before {
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: #4c418b;
	top: 0;
	left: 0;
	border-radius: 50%;
}

.legend ul.legend-list li.ui:before {
	background-color: #04aec6;
}

.legend ul.legend-list li.code:before {
	background-color: #ff2d78;
}

.legend.style1 .legend-list {
	float: left;
	text-align: left;
	margin-top: 62px;
}

.legend.style1 .legend-list li {
	display: block;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	padding-left: 20px;
	margin-bottom: 23px;
}

.legend.style1 ul.legend-list li:before {
	width: 12px;
	height: 12px;
	left: -6px;
	top: 2px;
}

.legend.style1 .legend-list li p {
	margin-top: 5px;
	color: #898989;
	font-size: 12px;
}

.legend.style2 {
	margin-top: 23px;
}

/* Chart Div */
#chartdiv {
	margin-bottom: 15px;
}

canvas {
	/* width: 100% !important; */
	top: 0;
	left: 0;
	
}

/* Box Project */
.box-project .box-content table thead tr {
	border-bottom: 1px solid #29292a;
}

.box-project .box-content table thead tr th,
.box-project .box-content table thead tr td {
	padding: 25px 0 17px;
	text-align: left;
    vertical-align: middle;
}

.box-project .box-content table thead tr td {
	padding: 20px 0 18px;
	font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
}

.box-project .box-content {
    overflow-x: hidden;
   	width: 100%;
   	height: auto;
    position: relative;
}

.box-project .box-content table {
	width: 620px;
	margin-top: 5px;
	overflow: hidden;
}

.box-project .box-content table thead tr th {
	font-weight: 500;
	font-family: 'Montserrat';
}

.box-project .box-content table thead tr th:nth-child(2) {
	padding-left: 19px;
	width: 35%;
}

.box-project .box-content table thead tr th:nth-child(3) {
	padding-left: 48px;
}

.box-project .box-content table thead tr th:nth-child(4) {
	text-align: right;
	padding-right: 4px;
}

.box-project .box-content table thead tr td:nth-child(1) {
	width: 7.5%;
}

.box-project .box-content table thead tr td:nth-child(4) {
	width: 15.3%;
	vertical-align: middle;
}

.box-project .box-content table tbody tr td.bg {
	padding: 8px 0;
}

.box-project .box-content table tbody tr td.bg span {
	background-color: #4c418b;
	font-size: 12px;
	font-weight: 500;
	height: 37px;
	line-height: 40px;
	color: #fff;
	padding-left: 48px;
	display: inline-block;
}

.box-project .box-content table tbody tr.design td.bg span,
.box-project .box-content table tbody tr.testing td.bg span {
	background-color: #00bcd5;
}

.box-project .box-content table tbody tr.cancel td.bg span {
	background-color: #ff2d78;
}

.box-project .box-content table tbody tr.cancel td:nth-child(4) {
	color: #fff;
}

/* Box Inbox */
.box-inbox .box-content ul {
	margin-top: 17px;
}

.box-inbox .box-content ul li {
	font-family: 'Montserrat';
    padding: 14px 0 13px;
	border-bottom: 1px solid #222426;
	margin-left: -3px;
	display: block;
}

.box-inbox .box-content ul li a {
	display: block;
	color: #898989;
}

.box-inbox .box-content ul li .left {
	float: left;
}

.box-inbox .box-content ul li .left img {
	border-radius: 50%;
	float: left;
	margin-right: 28px;
}

.box-inbox .box-content ul li .left .info {
	font-size: 10px;
	float: left;
	margin-top: 2px;
}

.box-inbox .box-content ul li .left .info .name {
	font-size: 12px;
	color: #c5c5c5;
	margin-bottom: 7px;
}

.box-inbox .box-content ul li .right {
	float: right;
	font-size: 10px;
	position: relative;
    right: 42px;
    top: 5px;
}


/* Box Kullanici */
.box-kullanici .box-content ul {
	margin-top: 17px;
}

.box-kullanici .box-content ul li {
	font-family: 'Montserrat';
    padding: 14px 0 13px;
	border-bottom: 1px solid #222426;
	margin-left: -3px;
	display: block;
}

.box-kullanici .box-content ul li a {
	display: block;
	color: #898989;
}

.box-kullanici .box-content ul li .left {
	float: left;
}

.box-kullanici .box-content ul li .left img {
	border-radius: 50%;
	float: left;
	margin-right: 28px;
}

.box-kullanici .box-content ul li .left .info {
	font-size: 10px;
	float: left;
	margin-top: 2px;
}

.box-kullanici .box-content ul li .left .info .name {
	font-size: 12px;
	color: #c5c5c5;
	margin-bottom: 7px;
}

.box-kullanici .box-content ul li .right {
	float: right;
	font-size: 10px;
	position: relative;
    right: 42px;
    top: 5px;
}

/* Main Message
-------------------------------------------------------------- */
#message,
#calendar {
	overflow: hidden;
	padding: 0 20px 0px;
	display: none;
}

/* Box Message
-------------------------------------------------------------- */
.box-message {
	box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.5);
	border-radius: 0px;
	border-top-left-radius: 7px;
	overflow: hidden;
	padding: 0;
	width: 425px;
	margin-bottom: 0px;
	background: none;
}

.box-message .box-header {
	margin-bottom: 0px;
}

.box-message .box-header .header-title {
	line-height: 100px;
	font-family: 'Oswald';
	font-weight: 500;
	color: #ebebeb;
	padding: 0 35px;
	background-color: #574c9d;
	position: relative;
	cursor: pointer;
}

.box-message .box-header .header-title:before {
	content: '\f107';
	position: absolute;
	font-family: 'fontawesome';
    right: 54px;
    top: 3px;
    font-size: 19px;
}

.box-message .box-header.active .header-title:before {
	content: '\f106';
}

.box-message .box-header .header-title span {
	margin-left: 21px;
	overflow: visible !important;
}

.box-message .box-content {
	height: 837px;
	background: -moz-linear-gradient(90deg, rgba(24,25,28,1) 0%, rgba(32,34,38,1) 65%, rgba(32,34,38,1) 100%); /* ff3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(32,34,38,1)), color-stop(35%, rgba(32,34,38,1)), color-stop(100%, rgba(24,25,28,1))); /* safari4+,chrome */
	background: -webkit-linear-gradient(90deg, rgba(24,25,28,1) 0%, rgba(32,34,38,1) 65%, rgba(32,34,38,1) 100%); /* safari5.1+,chrome10+ */
	background: -o-linear-gradient(90deg, rgba(24,25,28,1) 0%, rgba(32,34,38,1) 65%, rgba(32,34,38,1) 100%); /* opera 11.10+ */
	background: -ms-linear-gradient(90deg, rgba(24,25,28,1) 0%, rgba(32,34,38,1) 65%, rgba(32,34,38,1) 100%); /* ie10+ */
	background: linear-gradient(0deg, rgba(24,25,28,1) 0%, rgba(32,34,38,1) 65%, rgba(32,34,38,1) 100%); /* w3c */
}
.box-message .box-content ul {
	height: 630px;
	overflow: hidden;
}

.box-message .box-content ul li {
	cursor: pointer;
	display: block;
    padding: 40px 35px 25px 30px;
	border-bottom: 1px solid #2e3033;
	position: relative;
}

.box-message .box-content ul li.active {
	background-color: #18191c;
}

.box-message .box-content ul li.active:before {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 4px;
	background-color: #04aec6;
}

.box-message .box-content ul li .left {
	float: left;
}

.box-message .box-content ul li .left .avatar {
	float: left;
	margin-right: 30px;
	margin-top: 3px;
	position: relative;
}

.box-message .box-content ul li .left .avatar img {
	border-radius: 50%;
	width: 49px;
}

.box-message .box-content ul li .left .content {
	float: left;
}

.box-message .box-content ul li .left .content .username {
	position: relative;
	display: inline-block;
}

.box-message .box-content ul li .left .content .username span {
	position: absolute;
	top: -30px;
	right: -28px;
	height: 25px;
	width: 25px;
	line-height: 25px;
	text-align: center;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	background-color: #04aec6;
	border-radius: 50%;
}

.box-message .box-content ul li .left .content .username span:before {
	content: '';
	position: absolute;
    height: 38px;
    width: 38px;
    background-color: transparent;
    border: 4px solid #04aec6;
    top: -6px;
    left: -6px;
	border-radius: 50%;
	    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.heartbit {
	position: relative;
}

.heartbit:before {
	content: '';
	position: absolute;
    height: 38px;
    width: 38px;
    background-color: transparent;
    border: 4px solid #04aec6;
    top: -6px;
    left: -6px;
	border-radius: 50%;
	display: none;
	-moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.status-color.blue.heartbit.style1:before {
    width: 21px;
    height: 21px;
    top: -6px;
    left: -6.5px;
    z-index: -1;
    display: block;
}

.box-message .box-content ul li .status-color.blue.heartbit.style2:before,
.member-status.right .sidebar-member ul.member-list li .status-color.heartbit:before
.member-status.right .sidebar-member ul.member-list li .status-color.green.heartbit:before,
.member-status.right .sidebar-member ul.member-list li .status-color.pink.heartbit:before,
.member-status.right .sidebar-member ul.member-list li .status-color.grey.heartbit:before {
	display: none;
}

.box-message .box-content ul li:hover .status-color.blue.heartbit.style2:before {
	display: block;
	width: 18px;
    height: 18px;
    top: -5px;
    left: -5px;
    z-index: -1;
}

.member-status.right .sidebar-member ul.member-list li:hover .status-color.heartbit:before{
	display: block;
	width: 18px;
    height: 18px;
    top: -5px;
    left: -5px;
    z-index: -1;
    border: 4px solid #89e07d;
}

.member-status.right .sidebar-member ul.member-list li:hover .status-color.blue.heartbit:before{
	border: 4px solid #04aec6;
}

.member-status.right .sidebar-member ul.member-list li:hover .status-color.pink.heartbit:before {
    border: 4px solid #ff2d78;
}

.member-status.right .sidebar-member ul.member-list li:hover .status-color.grey.heartbit:before {
    border: 4px solid #c9c9c9;
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0; }
  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.6; }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.0; } 
}


.box-message .box-content ul li .left .content .username .name {
	font-size: 16px;
	font-weight: 600;
	color: #ebebeb;
	margin-bottom: 5px;
}

.box-message .box-content ul li .left .content .text p {
	font-size: 12px;
	line-height: 18px;
}

.box-message .box-content ul li .right {
	float: right;
}

.box-message .box-content ul li .right .date {
	font-size: 12px;
	margin-top: 3px;
}

.new-message {
	margin-top: 30px;
	text-align: center;
}

.new-message a {
	display: inline-block;
    line-height: 62px;
    height: 60px;
    padding: 0 45px 0 100px;
    color: #ebebeb;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #252528;
    position: relative;
    border-radius: 4px;
    letter-spacing: 0.3px;
}

.new-message a:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 60px;
	height: 1px;
	width: 15px;
	background-color: #fff;
}

.new-message a:after {
	content: '';
	position: absolute;
	top: 39%;
    left: 67px;
	height: 15px;
	width: 1px;
	background-color: #fff;
}

/* Mesage Info
-------------------------------------------------------------- */
 .message-info {
	float: left;
	border-top-right-radius: 7px;
	overflow: hidden;
	width: calc(100% - 425px);
}

.message-info .message-header {
	background-color: #fff;
}

.message-info .message-header .move-message {
    line-height: 106px;
    height: 100px;
    text-align: right;
    padding-right: 52px;
    letter-spacing: 0.6px;
}

.message-info .message-header .move-message span {
	margin-right: 15px;
}

.message-info .message-header .box-info-messager {
	padding-left: 50px;
	padding-bottom: 29px;
}

.message-info .message-header .box-info-messager .message-pic {
	float: left;
	margin-right: 31px;
	position: relative;
}

.message-info .message-header .box-info-messager .message-pic img {
	border-radius: 50%;
}

.message-info .message-header .box-info-messager .content .username {
	color: #232323;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 4px;
}

.message-info .message-header .box-info-messager .content .text p {
	line-height: 18px;
	color: #6c6b6b;
	font-size: 12px;
}

.message-info .message-box {
	background-color: #ededed;
	height: 664px;
	overflow: hidden;
}

.message-info .message-box .mCSB_inside > .mCSB_container {
	padding: 20px 50px 0px;
}

.message-info .message-box .message-in {
	float: left;
	text-align: left;
	margin-bottom: 20px;
	width: 100%;
}

.message-info .message-box .message-in .message-pic {
	float: left;
	margin-right: 32px;
	margin-top: 3px;
	position: relative;
}

.message-info .message-box .message-in .message-pic img {
	border-radius: 50%;
	margin-top: 5px;
}

.message-info .message-box .message-in .message-body {
	float: left;
}

.message-info .message-box .message-in .message-body .message-text {
    width: 348px;
    padding: 17px 30px 18px;
	float: left;
	background-color: #fff;
	border-radius: 6px;
	font-size: 12px;
	position: relative;
	word-break: break-word;
	box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05); 
}

.message-info .message-box .message-in .message-body .message-text:before {
    content: '';
    position: absolute;
    left: -20px;
    top: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 20px solid #fff;
}

.message-info .message-box .message-in .message-body .message-text p,
.message-info .message-box .message-out .message-body .message-text p {
	line-height: 20px;
	word-wrap: break-word;
}

.message-info .message-box .message-in .message-body .message-image ul li,
.message-info .message-box .message-out .message-body .message-image ul li {
	display: inline-block;
	margin: 10px 5px 0px;
}

.message-info .message-box .message-out {
	float: right;
	text-align: right;
	margin-bottom: 20px;
	width: 100%;
}

.message-info .message-box .message-out .message-pic {
	float: right;
	margin-left: 51px;
	margin-top: 7px;
	position: relative;
}

.message-info .message-box .message-out .message-pic img {
	border-radius: 50%;
}

.message-info .message-box .message-out .message-body {
	float: right;
}

.message-info .message-box .message-out .message-body .message-text {
	width: 410px;
    padding: 16px 30px 19px;
	background-color: #fff;
	border-radius: 5px;
	font-size: 12px;
	position: relative;
	box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05); 
}

.message-info .message-box .message-out .message-body .message-text:before {
    content: '';
    position: absolute;
    right: -20px;
    top: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid #fff;
}

.message-info .form-chat {
 	background-color: #fff;
 	padding: 0px 50px;
}

.message-info .form-chat form .message-form-chat span {
 	float: left;
 	margin-right: 20px;
 	line-height: 85px;
}

.message-info .form-chat form .message-form-chat span.pin {
	margin-right: 10px;
	width: 10px;
}

.message-info .form-chat form .message-form-chat span.btn-send {
	margin-right: 0;
	height: 85px;
    display: block;
    line-height: 86px;
}

.message-info .form-chat form .message-form-chat span.message-text {
	line-height: 1;
	margin-top: 16px;
	width: calc(100% - 243px);
}

.message-info .form-chat form .message-form-chat span button {
	display: inline-block;
	line-height: 32px;
	color: #6c6c6c;
	font-size: 16px;
    padding: 0 20px 0 22px;
	border: 1px solid #e7e7e7;
	border-radius: 15px;
}

.icon-mobile {
	display: none;
	float: left;
	line-height: 85px;
}

/* Calendar
-------------------------------------------------------------- */
#calendar .breadcrumbs {
	margin-bottom: 20px;
}

#calendar .breadcrumbs li {
	display: inline-block;
	font-family: 'Oswald';
	font-size: 16px;
}

#calendar .box {
	padding: 65px;
}

#box-calendar {
	min-width: 100%;
	height: 100%;
	overflow: auto;
}

#box-calendar {
	width: 920px;
}

#calendar .box .box-header {
	margin-bottom: 60px;
}

#calendar .box .box-header h2.title {
	font-size: 46px;
	font-weight: 300;
	line-height: 46px; 
}

#calendar .box .box-header h2.title span {
	font-weight: 200;
}

#calendar .box .box-header ul.date li {
	display: inline-block;
	color: #fff;
	height: 42px;
	line-height: 42px;
	padding: 0 21px;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	margin-left: 10px;
	cursor: pointer;
}

#calendar .box .box-header ul.date li.active,
#calendar .box .box-header ul.date li:hover {
	background: #6256a9;
	border-radius: 20px;
}


.fc-day-header.fc-widget-header {
	font-weight: bold;
	color: #c5c5c5;
	text-align: right;
	padding-right: 20px;
	padding-bottom: 15px;
	border: none;
}

.fc-day.fc-widget-content.fc-past {
	width: 14.3%;
	/* height: 100%; */
	box-shadow: 7px 10px 14px 0px rgba(0, 0, 0, 0.4);
}

td.fc-head-container.fc-widget-header {
    border-color: transparent;
	border-bottom: 1px solid #232529;
	box-shadow: 7px 10px 14px 0px rgba(0, 0, 0, 0.4);
}

.fc-body > tr > .fc-widget-content {
    border: transparent;
}

.fc-basic-view .fc-body .fc-row,
td.fc-day.fc-widget-content.fc-future {
	box-shadow: 7px 10px 14px 0px rgba(0, 0, 0, 0.4);
}

.box.calendar {
	width: calc(100% - 328px);
}

#external-events.box {
	float: right;
	box-shadow: none;
}

#external-events.box {
	padding: 30px 20px;
	border-radius: 5px;
}

#external-events .external-event {
    padding: 20px 20px;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 2px;
    color: #fff;
    cursor: move;
}

#external-events .external-event:last-child {
	margin-bottom: 0px;
}

.external-event .event-title {
	font-size: 18px;
	font-weight: bold;
	line-height: 60px;
}

.external-event .event-title span {
	font-size: 60px;
	vertical-align: middle;
	padding-right: 20px;
}

.external-event .event-content {
	text-align: center;
	margin-top: 10px;
}

.external-event .event-content .date {
	margin-bottom: 10px;
}

.external-event .event-content .time {
	font-weight: 400;
}

.external-event.bg-purple {
	background: #6256a9;
}

.external-event.bg-aqua {
	background: #04aec6;
}

.external-event.bg-blue {
	background: #3d5afe;
}

.external-event.bg-pink {
	background: #ff4080;
}

.external-event.bg-orange {
	background: #f09238;
}

/* Calendar Footer */
#calendar .footer {
	padding: 30px 0;
	margin-top: 20px;
	margin-right: -20px;
	background: -webkit-linear-gradient(90deg, #18191c, #18191c 48%, #202226);
    background: -o-linear-gradient(90deg, #18191c, #18191c 48%, #202226);
    background: -moz-linear-gradient(90deg, #18191c, #18191c 48%, #202226);
    background: linear-gradient(90deg, #18191c, #18191c 48%, #202226);
}

#calendar .footer .copyright {
	float: left;
}

#calendar .footer .copyright p,
#calendar .footer ul.menu-ft li a {
	font-size: 16px;
	font-weight: 300;
	font-family: 'Oswald';
}

#calendar .footer ul.menu-ft {
	float: right;
}

#calendar .footer ul.menu-ft li {
	display: inline-block;
	padding: 0 20px;
}

/* Notify */
ul.notify li {
	padding: 20px;
	border-bottom: 1px solid #29292a;
}

ul.notify li .avatar {
	border-radius: 50%;
	overflow: hidden;
	float: left;
	margin-right: 15px;
}

ul.notify li .notify-content {
	margin-top: 5px;
}

.divider35 {
	height: 35px;
}

.divider22 {
	height: 22px;
}

.divider50 {
	height: 50px;
}

/* Preload
-------------------------------------------------------------- */
.loader {
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  top: 40%;
  left: 50%;
  margin-left: -32px;
  transform: translateX(-50%);
  border-radius: 50%;  
}

.inner.one {
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #247abb;
}

.inner.two {
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #247abb;
}

.inner.three {
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #247abb;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
/* Modal penceresi stil tanımlamaları */
.modal {
	display: flex;
	align-items: center; /* Dikey hizalama */
	justify-content: center; /* Yatay hizalama */
	position: fixed;
	z-index: 1000; /* Yeterli bir z-index değeri */
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4); /* Siyah arka plan ile biraz şeffaflık */
  }
  
  .modal-content {
	background-color: #fefefe;
	padding: 20px;
	border: 1px solid #888;
	width: auto; /* İhtiyaca göre ayarlayın */
	height: auto;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	/* Yeni eklenen transform ile merkezleme */
	transform: translate(-50%, -30%);
	position: absolute;
	top: 30%;
	left: 50%;
  }
  
  /* Modal penceresini aktif ettiğinizde */
  .modal.active {
	display: block; /* Modalı göster */
  }
  
  
  
  .close-button {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
  }
  
  .close-button:hover,
  .close-button:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
  }
  #page-numbers {
	list-style: none;
	display: flex;
	justify-content: center;
	padding: 0;
  }
  
  #page-numbers li {
	cursor: pointer;
	padding: 5px 10px;
	border: 1px solid #ccc;
	margin: 0 5px;
  }
  
  #page-numbers li.active {
	background-color: #007bff;
	color: white;
  }
/* Toast mesajlarının z-index değerini artırarak üstte kalmasını sağlar */
.Toastify__toast-container--top-right {
	z-index: 9999 !important; /* Mevcut z-index değerinden daha yüksek bir değer */
  }
  
  /* Eğer toast mesajlarını sağ üstte göstermek istiyorsanız */
  .Toastify__toast-container {
	top: 10em; /* Üstten boşluk */
	right: 1em; /* Sağdan boşluk */
  }
  .my-toast-container {
	/* Z-index değerini artırarak öğenin diğer içeriklerin üstünde görünmesini sağlar */
	z-index: 9999;
  
	/* Toast mesajlarının ekrandaki konumunu ayarlar */
	top: 10em; /* Üstten boşluk */
	right: 1em; /* Sağdan boşluk */
  
	/* Font stilini özelleştirir */
	font-family: 'Arial', sans-serif; /* Yazı tipi */
	font-size: 0.9em; /* Yazı boyutu */
  
	/* Arka plan ve metin rengini özelleştirir */
	background: rgba(50, 50, 50, 0.9); /* Yarı saydam siyah arka plan */
	color: white; /* Beyaz yazı rengi */
  
	/* Kenarlık yuvarlaklığını ve gölgeyi ayarlar */
	border-radius: 5px; /* Köşeleri yuvarlaklaştırır */
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Hafif bir gölge ekler */
  
	/* Diğer elemanlarla arasındaki boşluğu ayarlar */
	margin: 0.5em;
  }
  
  /* Toast mesajının içindeki her bir toast öğesine özel stil tanımlamaları */
  .Toastify__toast {
	background: rgba(61, 61, 61, 0.9); /* Her bir toast elemanı için arka plan rengi */
	color: white; /* Her bir toast elemanı için metin rengi */
  }
  .table {
	/* Tablo için genel stiller */
	color: #fff;
	background-color: #333;
	/* ... */
  }
  
  .table th,
  .table td {
	/* Sütun başlıkları ve hücreler için stiller */
	padding: 10px;
	border: 1px solid #444;
	/* ... */
  }
  
  /* Sayfalama için özel stiller */
  .pagination {
	/* ... */
  }
  

/*   .fixed {
	position: fixed;
  }
  
 .-mx-4 {
	margin-left: -1rem;
	margin-right: -1rem;
  }


   */
  
   .-my-2 {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
  }

  
   .mt-2 {
	margin-top: 1.5rem;
  }
  .box-content {
	box-sizing: content-box;
  }
  
  .inline-block {
	display: inline-block;
  }
  
  .flex {
	display: flex;
  }


  table {
	text-indent: 0;
	/* 1 */
	border-color: inherit;
	/* 2 */
	border-collapse: collapse;
	/* 3 */
  }
  .table {
	display: table;
  }
  
  .min-w-full {
	min-width: 100%;
  }
  
  .table-auto {
	table-layout: auto;
  }  

  .flex-col {
	flex-direction: column;
  }
  
  .items-center {
	align-items: center;
  }
  
  .justify-between {
	justify-content: space-between;
  }
  
  .divide-y > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-y-reverse: 0;
	border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
	border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  
  .divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-opacity: 1;
	border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  }
  
  .overflow-hidden {
	overflow: hidden;
  }
  
  .overflow-x-auto {
	overflow-x: auto;
  }
  
  .whitespace-nowrap {
	white-space: nowrap;
  }
  
  .border {
	border-width: 1px;
  }
  
  .border-b {
	border-bottom-width: 1px;
  }
  
  .border-t {
	border-top-width: 1px;
  }
  
  .border-gray-200 {
	--tw-border-opacity: 1;
	border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  
  .bg-gray-800 {
	--tw-bg-opacity: 1;
	background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }
  
  .bg-sky-700 {
	--tw-bg-opacity: 1;
	background-color: rgb(3 105 161 / var(--tw-bg-opacity));
  }
  
  .px-4 {
	padding-left: 1rem;
	padding-right: 1rem;
  }
  
  .px-6 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
  }
  
  .py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
  }
  
  .py-3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
  }
  
  .py-4 {
	padding-top: 1rem;
	padding-bottom: 1rem;
  }
  
  .text-left {
	text-align: left;
  }
  
  .align-middle {
	vertical-align: middle;
  }
  
  .text-3xl {
	font-size: 1.875rem;
	line-height: 2.25rem;
  }
  
  .text-xs {
	font-size: 0.75rem;
	line-height: 1rem;
  }
  
  .font-bold {
	font-weight: 700;
  }
  
  .font-medium {
	font-weight: 500;
  }
  
  .uppercase {
	text-transform: uppercase;
  }
  
  .tracking-wider {
	letter-spacing: 0.05em;
  }
  
  .text-gray-200 {
	--tw-text-opacity: 1;
	color: rgb(229 231 235 / var(--tw-text-opacity));
  }
  
  .text-white {
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  
  .underline {
	text-decoration-line: underline;
  }
  
  .shadow {
	--tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	--tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  
  .blur {
	--tw-blur: blur(8px);
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  
  .filter {
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  
  .hover\:bg-sky-800:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(7 89 133 / var(--tw-bg-opacity));
  }
  @media (min-width: 640px) {
	.sm\:-mx-6 {
	  margin-left: -1.5rem;
	  margin-right: -1.5rem;
	}
  
	.sm\:rounded-lg {
	  border-radius: 0.5rem;
	}
  
	.sm\:px-6 {
	  padding-left: 1.5rem;
	  padding-right: 1.5rem;
	}
  
	.sm\:px-8 {
	  padding-left: 2rem;
	  padding-right: 2rem;
	}
  
	.sm\:py-3 {
	  padding-top: 0.75rem;
	  padding-bottom: 0.75rem;
	}
  }
  
  @media (min-width: 1024px) {
	.lg\:-mx-8 {
	  margin-left: -2rem;
	  margin-right: -2rem;
	}
  
	.lg\:px-8 {
	  padding-left: 2rem;
	  padding-right: 2rem;
	}
  }




  hr {
	height: 0;
	/* 1 */
	color: inherit;
	/* 2 */
	border-top-width: 1px;
	/* 3 */
  }
  
  /*
  Add the correct text decoration in Chrome, Edge, and Safari.
  */
  
  abbr:where([title]) {
	-webkit-text-decoration: underline dotted;
			text-decoration: underline dotted;
  }
  
  /*
  Remove the default font size and weight for headings.
  */
  
 /*  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
	font-size: inherit;
	font-weight: inherit;
  }
   */
  /*
  Reset links to optimize for opt-in styling instead of opt-out.
  */
  
  a {
	color: inherit;
	text-decoration: inherit;
  }
  
  /*
  Add the correct font weight in Edge and Safari.
  */
  
  b,
  strong {
	font-weight: bolder;
  }
  
  /*
  1. Use the user's configured `mono` font-family by default.
  2. Use the user's configured `mono` font-feature-settings by default.
  3. Use the user's configured `mono` font-variation-settings by default.
  4. Correct the odd `em` font sizing in all browsers.
  */
  
  code,
  kbd,
  samp,
  pre {
	font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	/* 1 */
	font-feature-settings: normal;
	/* 2 */
	font-variation-settings: normal;
	/* 3 */
	font-size: 1em;
	/* 4 */
  }
  
  /*
  Add the correct font size in all browsers.
  */
  
  small {
	font-size: 80%;
  }
  
  /*
  Prevent `sub` and `sup` elements from affecting the line height in all browsers.
  */
  
  sub,
  sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
  }
  
  sub {
	bottom: -0.25em;
  }
  
  sup {
	top: -0.5em;
  }
  
  /*
  1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
  2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
  3. Remove gaps between table borders by default.
  */
  
  table {
	text-indent: 0;
	/* 1 */
	border-color: inherit;
	/* 2 */
	border-collapse: collapse;
	/* 3 */
  }
  
  /*
  1. Change the font styles in all browsers.
  2. Remove the margin in Firefox and Safari.
  3. Remove default padding in all browsers.
  */
  
/*   button,
  input,
  optgroup,
  select, */
  buttonx,
  textarea {
	font-family: inherit;
	/* 1 */
	font-feature-settings: inherit;
	/* 1 */
	font-variation-settings: inherit;
	/* 1 */
	font-size: 100%;
	/* 1 */
	font-weight: inherit;
	/* 1 */
	line-height: inherit;
	/* 1 */
	color: inherit;
	/* 1 */
	margin: 0;
	/* 2 */
	padding: 0;
	/* 3 */
  }
  
  /*
  Remove the inheritance of text transform in Edge and Firefox.
  */
  





/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
	outline: auto;
  }
  
  /*
  Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
  */
  
  :-moz-ui-invalid {
	box-shadow: none;
  }
  
  /*
  Add the correct vertical alignment in Chrome and Firefox.
  */
  
  progress {
	vertical-align: baseline;
  }
  
  /*
  Correct the cursor style of increment and decrement buttons in Safari.
  */
  
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
	height: auto;
  }
  
  /*
  1. Correct the odd appearance in Chrome and Safari.
  2. Correct the outline style in Safari.
  */
  


/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Reset default styling for dialogs.
*/

dialog {
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}
.columns-3 {
	-webkit-column-count: 3; /* Chrome, Safari, Opera */
	-moz-column-count: 3;    /* Firefox */
	column-count: 3;
	
	-webkit-column-gap: 1em; /* Chrome, Safari, Opera */
	-moz-column-gap: 1em;    /* Firefox */
	column-gap: 1em;
  }
  .img-nav-link {
	
	align-items: center;
	position: relative; /* Pozisyon ayarları için bir referans noktası oluşturur */
  }
  .img-nav .notification-badge {


	position: absolute;
	font-family: 'Montserrat';
    top: -4px;
    right: -4px;
    background-color: #9b0c43; /* Arka plan rengi */
    color: #fff;
    font-size: 5px;
    font-weight: 400;
    height: 20px;
    width: 16px;
    line-height: 18px;
	text-align: center;
	border-radius: 50%;
	transform: translate(-450%, -20%); /* Konumlandırma */

	

  }
  