/* @import '~react-clock/dist/Clock.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-image-crop/dist/ReactCrop.css';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import '~react-dropzone-uploader/dist/styles.css';
@import '~react-dragula/dist/dragula.css';
@import '~@asseinfo/react-kanban/dist/styles.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-image-crop/dist/ReactCrop.css';
@import 'react-day-picker/dist/style.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~react-vertical-timeline-component/style.min.css';
@import '~easymde/dist/easymde.min.css';
@import '~emoji-mart/css/emoji-mart.css';
@import '~leaflet/dist/leaflet.css';
@import '~react-image-lightbox/style.css';
@import '~react-perfect-scrollbar/dist/css/styles.css'; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
